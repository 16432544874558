<template>
    <section id="app-header">
        <navbar :customStyles="customStyles"></navbar>
    </section>
</template>

<script>
import Navbar from '@/components/menus/Navbar.vue';

export default {
    components: {
        Navbar
    },
    computed: {
        // custom navbar styles
        customStyles() {
            if(this.$route.name === 'WineDetail' || this.$route.name === 'TeamDetail' || this.$route.name === 'Gallery' || 
              (this.$route.name === 'StaticInfos' && (this.$route.params.slug === 'legal' || this.$route.params.slug === 'qualidade')) )
            {
                return {
                    background: 'bg-primary-color',
                    text: 'secondary-color'
                };
            }
            return {
                background: 'navbar-default-background',
                text: 'navbar-default-text'
            };
        }
    }
};
</script>
<style scoped>
#app-header{
    height: 115px;
    background-color: transparent;
    position: absolute;
    width: 100%;
    display: flex;
    z-index: 11;
    top: 0;
}
@media (max-width: 992px) {
    #app-header {
        height: 80px;
    }
}
</style>