import { WINES_SERVICE } from "@/services/wines.service.js";

const state = { listOutput: null, detailOutput: null};

const actions = {
    getList({ dispatch, commit }, inputGetWines) {
        dispatch('alert/clear', null, { root: true });
        commit('listRequest');
        
        WINES_SERVICE.list(inputGetWines)
            .then(
                output => {
                    commit('listSuccess', output);
                },
                error => {
                    commit('listFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    getDetails({ dispatch, commit }, slug) {
        dispatch('alert/clear', null, { root: true });
        commit('listDetailRequest');
        
        WINES_SERVICE.getDetail(slug)
            .then(
                output => {
                    commit('listDetailSuccess', output);
                },
                error => {
                    commit('listDetailFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};

const mutations = {
    /* List */
    listRequest(state) {
        state.listOutput = null;
    },
    listSuccess(state, output) {
        setTimeout(() => state.listOutput = output, 1000);
    },
    listFailure(state) {
        state.listOutput = false;
    },
    /* Detail */
    listDetailRequest(state) {
        state.detailOutput = null;
    },
    listDetailSuccess(state, output) {
        setTimeout(() => state.detailOutput = output, 1000);
    },
    listDetailFailure(state) {
        state.detailOutput = false;
    }
};

export const wines = {
    namespaced: true,
    state,
    actions,
    mutations
};