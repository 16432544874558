<template>
  <div class="contact">
    <LogoLoading v-show="settingsOutput === null" />
    <div class="top-img" :class="{ loaded: imageLoaded }">
      <img :src="contactImage" class="top-bg" @load="onImageLoad" />
    </div>
    <div class="container mt-5 mb-5" v-if="getSettings">
      <div class="row justify-content-center">
        <div class="col-lg-10 col-md-12">
          <div class="wrapper">
            <div class="row no-gutters">
              <div class="col-md-7 d-flex align-items-stretch">
                <div class="contact-wrap w-100 p-md-5 p-4">
                  <h3 class="mb-4">{{ $t("SendUsAMessage") }}</h3>
                  <form action="" @submit.prevent="handleMessageSubmit">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <input type="text" class="form-control" id="name" :placeholder="$t('name')" v-model="name"
                            :class="{
                              'is-invalid': submitted && hasError('Name'),
                            }" />
                          <div v-if="submitted && hasError('Name')" class="invalid-feedback">
                            <span v-if="!$v.name.required">{{
                              $t("contact.name")
                            }}</span>
                            <span v-if="errors.Name">{{ errors.Name }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <input type="email" class="form-control" id="email" :placeholder="$t('email')" v-model="email"
                            :class="{
                              'is-invalid': submitted && hasError('Email'),
                            }" />
                          <div v-if="submitted && hasError('Email')" class="invalid-feedback">
                            <span v-if="!$v.email.required">{{
                              $t("contact.email")
                            }}</span>
                            <span v-if="errors.Email">{{ errors.Email }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <VuePhoneNumberInput default-country-code="PT" id="inputContactNumber" :translations="{
                            phoneNumberLabel: $t('phoneNumber'),
                          }" v-model="phoneNumber" :class="{
                            'is-invalid':
                              submitted && hasError('PhoneNumber'),
                          }" :error="submitted && hasError('PhoneNumber')" />
                          <div v-if="submitted && hasError('PhoneNumber')" class="invalid-feedback">
                            <span v-if="!$v.phoneNumber.required">{{
                              $t("contact.phoneNumber")
                            }}</span>
                            <span v-if="errors.PhoneNumber">{{
                              errors.PhoneNumber
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <textarea class="form-control" id="message" cols="30" rows="7" :placeholder="$t('message')"
                            v-model="message" :class="{
                              'is-invalid': submitted && hasError('Message'),
                            }"></textarea>
                          <div v-if="submitted && hasError('Message')" class="invalid-feedback">
                            <span v-if="!$v.message.required">{{
                              $t("contact.message")
                            }}</span>
                            <span v-if="errors.Message">{{
                              errors.Message
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <button class="btn-border-primary mt-5" :class="{ disabled: !isFormCompleted }">
                            <span v-show="!loading" class="m-2">{{
                              $t("sendMessage")
                            }}</span>
                            <span v-show="loading" class="spinner-border spinner-border-sm" role="status"
                              aria-hidden="true"></span>
                            <span v-show="loading" class="sr-only">{{
                              $t("loading")
                            }}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="col-md-5 d-flex align-items-stretch">
                <div class="info-wrap w-100 p-lg-5 p-4">
                  <h3 class="mb-4 mt-md-4">{{ $t("contact.title") }}</h3>
                  <div class="dbox w-100 d-flex align-items-start">
                    <div class="
                        icon
                        d-flex
                        align-items-center
                        justify-content-center
                      ">
                      <b-icon icon="geo-alt-fill"></b-icon>
                    </div>
                    <div class="text px-3" v-html="getSettings.address"></div>
                  </div>
                  <div class="dbox w-100 d-flex align-items-center">
                    <div class="
                        icon
                        d-flex
                        align-items-center
                        justify-content-center
                      ">
                      <b-icon icon="telephone-fill"></b-icon>
                    </div>
                    <div class="text px-3">
                      <p>
                        <a :href="`tel:${this.getSettings.phone}`" target="_blank">{{ getSettings.phone }}</a>
                      </p>
                    </div>
                  </div>
                  <div class="dbox w-100 d-flex align-items-center">
                    <div class="
                        icon
                        d-flex
                        align-items-center
                        justify-content-center
                      ">
                      <b-icon icon="envelope-fill"></b-icon>
                    </div>
                    <div class="text px-3 text-break">
                      <p>
                        <a :href="`mailto:${getSettings.email}`">{{
                          getSettings.email
                        }}</a>
                      </p>
                    </div>
                  </div>
                  <div class="dbox w-100 d-flex align-items-center">
                    <div class="
                        icon
                        d-flex
                        align-items-center
                        justify-content-center
                      ">
                      <b-icon icon="map-fill"></b-icon>
                    </div>
                    <div class="text px-3">
                      <p><a :href="location" target="_blank">Google maps</a></p>
                    </div>
                  </div>
                  <div class="
                    dbox w-100 d-flex align-items-center justify-content-center
                    ">
                    <a href="https://www.livroreclamacoes.pt/Inicio/" target="_blank">
                      <img src="@/assets/img/livro_reclamacoes.png" alt="" height="65px" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { required, email } from "vuelidate/lib/validators";
import { UTILS_HELPER } from "@/helpers/utils.js";
import { API_HELPER } from "@/helpers/api.js";
import LogoLoading from "@/components/LogoLoading.vue";
import InputGetList from "@/classes/InputGetList";

export default {
  metaInfo() {
    return {
      title: this.$t("vueRoutes.contactTtile"),
      titleTemplate: "%s - Quinta Do Barbusano",
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: [
        { name: "description", content: this.$t("vueRoutes.contactDescription")},
        { name: "keywords", content: this.$t("vueRoutes.contactKeywords") },

        // Twitter Card
        // { name: "twitter:card", content: "summary" },
        // { name: "twitter:title", content: this.$t("vueRoutes.contactTtile") },
        // { name: "twitter:description", content: this.$t("vueRoutes.contactDescription")},

        // //Facebook OpenGraph
        // { property: "og:title", content: this.$t("vueRoutes.contactTtile") },
        // { property: 'og:site_name', content: 'Quinta Do Barbusano' },
        // { property: "og:type", content: "website" },
        // { property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_FRONT, "/default.jpg"]) },
        // { property: "og:description", content: this.$t("vueRoutes.contactDescription")},
      ],
      link: [
        {
          rel: "alternate",
          hreflang: "pt",
          href: API_HELPER.pathJoin([
            process.env.VUE_APP_URL_ROOT,
            this.$t("vueRoutes.contact", "pt"),
          ]),
        },
        {
          rel: "alternate",
          hreflang: "en",
          href: API_HELPER.pathJoin([
            process.env.VUE_APP_URL_ROOT,
            this.$t("vueRoutes.contact", "en"),
          ]),
        },
      ],
    };
  },
  components: {
    VuePhoneNumberInput,
    LogoLoading,
  },
  data() {
    return {
      submitted: false,
      loading: false,
      name: "",
      email: "",
      phoneNumber: "",
      message: "",
      showConfirmationPopup: false,
      resultMessage: null,
      imageLoaded: false,
    };
  },
  validations: {
    name: { required },
    email: { email, required },
    phoneNumber: { required },
    message: { required },
  },
  mounted() {
    this.getList();
    this.initializeHero();
  },
  methods: {
    ...mapActions("messages", ["send"]),
    ...mapActions("settings", ["getList"]),
    ...mapActions({
      clearAlert: "alert/clear",
      getHeros: 'heros/getList',
    }),
    onImageLoad() {
      this.imageLoaded = true;
    },
    handleMessageSubmit() {
      this.clearAlert();
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.loading = true;

      const { name, email, phoneNumber, message } = this;
      this.send({ name, email, phoneNumber, message });
    },
    resetForm() {
      this.submitted = false;
      this.name = "";
      this.email = "";
      this.phoneNumber = "";
      this.message = "";
    },
    hasError(fieldName) {
      return (
        this.$v[UTILS_HELPER.convertToCamelCase(fieldName)].$error ||
        Object.prototype.hasOwnProperty.call(this.errors, fieldName)
      );
    },
    initializeHero() {
      // GET list of hero
      const itemsPerPage = 99;
      const currentPage = 1;
      const sorter = 1; // asc
      const propertyName = "id";
      const searchText = '';

      //Make request
      var input = new InputGetList(itemsPerPage, currentPage, sorter, propertyName, searchText);

      // List specific parameters
      input.visible = true;

      this.getHeros(input);
    },
  },
  computed: {
    ...mapState("messages", ["processTask"]),
    ...mapState("settings", ["settingsOutput"]),
    ...mapState({
      alertType: (state) => state.alert.type,
      alertOutput: (state) => state.alert.output,
      herosOutput: state => state.heros.listOutput,
    }),
    isFormCompleted: function () {
      return this.name || this.email || this.phoneNumber || this.message;
    },
    errors: function () {
      return this.alertOutput && this.alertOutput.Errors
        ? this.alertOutput.Errors
        : {};
    },
    getSettings() {
      return this.settingsOutput && this.settingsOutput.data
        ? this.settingsOutput.data
        : null;
    },
    location() {
      let url = `https://www.google.com/maps/place/${this.getSettings.latitude},${this.getSettings.longitude}/`;
      return url;
    },
    contactImage() {
      let result = null;

      if (this.herosOutput && this.herosOutput.data && this.herosOutput.data.heroItems && this.herosOutput.data.heroItems.length) {
        // Get the first image of type Contact
        const item = this.herosOutput.data.heroItems.find(obj => obj.types && obj.types.some(typeObj => typeObj.type === 'Contact'));
        result = item && item.image ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, item.image]) : null;
      }

      return result;
    },

    // settingsStored: function(){
    //     return localStorage ? JSON.parse(localStorage.settings) : null;
    // },
  },

  watch: {
    processTask(val) {
      if (val != null) {
        this.loading = false;

        if (val) {
          alert(this.$i18n.t("contact.messageSuccess"));
          //clean form when submitted was done
          this.resetForm();
        } else {
          alert(this.$i18n.t("contact.messageError"));
        }
      }
    },
  },
};
</script>

<style scoped>
.top-img {
  position: relative;
  width: 100%;
  height: 300px;
}

.top-img .top-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.top-img::before {
  content: "";
  width: 100%;
  height: 80%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, #000, rgba(84, 84, 84, 0));
  mix-blend-mode: multiply;
  opacity: 0;
  z-index: 4;
  transition: opacity 0.5s ease;
}

.top-img.loaded::before {
  opacity: 0.45;
}

.contact h1 {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  color: white;
  font-weight: bold;
  z-index: 4;
}

.wrapper {
  width: 100%;
  box-shadow: 0px 21px 41px -13px rgba(0, 0, 0, 0.18);
}

.contact-wrap {
  background: #fff;
}

#contactForm .form-control {
  font-size: 16px;
}

.form-group {
  margin-bottom: 1rem;
}

.info-wrap {
  background-color: var(--secondary-color);
}

.info-wrap h3 {
  color: white;
}

.info-wrap .dbox {
  width: 100%;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 25px;
}

.info-wrap .dbox:last-child {
  margin-bottom: 0;
}

.info-wrap .dbox p {
  margin-bottom: 0;
}

.info-wrap .dbox p span {
  font-weight: 600;
  color: white;
}

.info-wrap .dbox p a {
  color: white;
  text-decoration: none;
}

.info-wrap .dbox .icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.6);
}

.info-wrap .dbox .icon span {
  font-size: 1rem;
  color: white;
}

.info-wrap .dbox .text {
  width: calc(100% - 50px);
}

:deep(button:focus) {
  border-color: var(--primary-color) !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
    0 0 8px rgba(150, 36, 34, 0.075) !important;
  outline: 0 none !important;
  caret-color: var(--primary-color) !important;
}

@media (min-width: 992px) {
  .info-wrap {
    border-radius: 5px;
  }
}
</style>
