<template>
  <div id="gallery">
    <!-- Loading -->
    <LogoLoading v-if="loading" />
    <!-- Content -->
    <div v-else class="photo-container container d-flex flex-wrap p-3">
      <GalleryThumb @showImage="showImage(index)" class="slider-thumb box" v-for="(content, index) in photos"
        :key="index" :galleryContent="content.image" />
      <NoResultsPlaceholder v-if="!photos.length"></NoResultsPlaceholder>
    </div>
    <FullScreenFile v-if="showImageIndex !== null" :startIndex="showImageIndex" @closeRequest="showImageIndex = null"
      :gallery="photos"></FullScreenFile>
  </div>
</template>

<script>
import GalleryThumb from "@/components/GalleryThumb.vue";
import LogoLoading from "@/components/LogoLoading.vue"
import { mapActions, mapState } from "vuex";
import InputGetList from "@/classes/InputGetList";
import FullScreenFile from "@/components/FullScreenFile.vue";
import { API_HELPER } from "@/helpers/api.js";
import NoResultsPlaceholder from "@/components/NoResultsPlaceholder.vue"

const DEFAULT_ITEMS_PER_PAGE = 8;

export default {
  components: { GalleryThumb, LogoLoading, FullScreenFile, NoResultsPlaceholder },
  data() {
    return {
      type: null,
      title: '',
      showImageIndex: null
    }
  },
  metaInfo() {
    return {
      title: this.$t("vueRoutes.galleryTitle"),
      titleTemplate: "%s - Quinta Do Barbusano",
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: [
        { name: 'description', content: this.$t("vueRoutes.galleryDescription") },
        { name: 'keywords', content: this.$t("vueRoutes.galleryKeywords") },
          // Twitter Card
          // { name: "twitter:card", content: "summary" },
          // { name: "twitter:title", content: this.$t("vueRoutes.galleryTitle") },
          // { name: "twitter:description", content: this.$t("vueRoutes.galleryDescription")},

          // //Facebook OpenGraph
          // { property: "og:title", content: this.$t("vueRoutes.galleryTitle") },
          // { property: 'og:site_name', content: 'Quinta Do Barbusano' },
          // { property: "og:type", content: "website" },
          // { property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_FRONT, "/default.jpg"]) },
          // { property: "og:description", content: this.$t("vueRoutes.galleryDescription")},
      ],
      link: [
        { rel: 'alternate', hreflang: 'en', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.gallery", 'en')]) },
        { rel: 'alternate', hreflang: 'pt', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.gallery", 'pt')]) },
      ]
    }
  },
  mounted() {
    this.initializePage();
  },
  methods: {
    ...mapActions({
      getGallery: 'heros/getList',
    }),
    initializePage() {
      const itemsPerPage = 99;
      const currentPage = 1;
      const searchText = '';
      const sorter = 1; // asc
      const propertyName = "id";

      // Make request
      var input = new InputGetList(itemsPerPage, currentPage, sorter, propertyName, searchText);
      this.getGallery(input);
    },
    showImage(index) {
      this.showImageIndex = index;
    },
  },
  computed: {
    ...mapState({
      galleryOutput: state => state.heros.listOutput,
    }),
    loading() {
      return this.galleryOutput == null;
    },
    photos() {
      let result = [];
      if (this.galleryOutput && this.galleryOutput.data && this.galleryOutput.data.heroItems && this.galleryOutput.data.heroItems.length) {
        // Filter only gallery items
        result = this.galleryOutput.data.heroItems.filter(obj => obj.types && obj.types.some(typeObj => typeObj.type === 'Gallery'));
      }
      return result;
    },

  }
}
</script>

<style scoped>
/* Title */
h1 {
  font-size: 3rem !important;
}

.title {
  justify-content: center;
  display: flex;
}

.title h2 {
  margin: 0;
  font-weight: bold !important;
}

hr {
  opacity: 1 !important;
  background-color: transparent;
  border: 1px solid black !important;
  margin: 1rem auto;
}

.see>p {
  margin-top: 1rem;
  cursor: pointer;
}

.skeleton-box {
  min-height: 245px;
}

/* Size of poi elements: these are not done using bootstrap to have correct alignments, using gap */
.photo-container

/* Four columns */
  {
  gap: 2%;
  min-height: calc(100vh - 115px);
  margin-top: 115px;
}

.photo-container>.slider-thumb,
.photo-container>span {
  width: 22%;
  height: 245px;
  margin-bottom: 2%;
  flex-grow: 1;
  max-width: 310px;
}

@media (max-width: 1199px) {
  .photo-container {
    gap: 5%;
    padding: 0 1rem;
  }

  .photo-container>.slider-thumb,
  .photo-container>span {
    width: 30%;
    max-width: 30%;
    margin-bottom: 5%;
  }
}

@media (max-width: 992px) {
  #menu-background {
    height: 80px;
  }
}

/* Header bar */
@media (max-width: 767px)
/* Two columns */
  {
  .photo-container {
    gap: 10%;
    padding: 0 1rem;
  }

  .photo-container>.slider-thumb,
  .photo-container>span {
    width: 45%;
    max-width: 45%;
    margin-bottom: 10%;
  }
}

@media (max-width: 575px)
/* Two columns */
  {
  h1 {
    font-size: 2rem !important;
  }

  .photo-container {
    justify-content: center;
  }

  .photo-container>.slider-thumb,
  .photo-container>span {
    width: 95%;
    max-width: 95%;
    margin-bottom: 3%;
  }

  .title h2 {
    font-size: 1rem;
  }
}
</style>
