import Vue from "vue";
import Vuex from "vuex";
import { alert} from '@/store/alert.module';
import { staticInfos} from '@/store/staticInfos.module';
import { heros } from '@/store/heros.module';
import { prizes } from '@/store/prizes.module';
import { members } from '@/store/members.module';
import { wines } from '@/store/wines.module';
import { messages } from '@/store/messages.module';
import { settings } from '@/store/settings.module';
import { about } from '@/store/about.module';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    alert,
    staticInfos,
    heros,
    prizes,
    members,
    wines,
    messages,
    settings,
    about
  },
});
