import Vue from "vue";
import VueRouter from "vue-router";
import i18n from '@/helpers/i18n';

//Views
import HomePage from "../views/HomePage.vue";
import WinesPage from "../views/WinesPage.vue";
import WineDetail from "../views/WineDetail.vue";
import Contact from "../views/Contact.vue";
import StaticInfos from "../views/StaticInfos.vue";
import TeamDetail from "../views/TeamDetail.vue";
import Gallery from "../views/Gallery.vue";

//Header and footer
import AppHeader from "../layout/AppHeader.vue";
import AppFooter from "../layout/AppFooter.vue";

// Page 404
import Page404 from "../views/Page404.vue";

Vue.use(VueRouter);

import { CONSTANTS } from '@/helpers/consts';

const routes = [
  {
    path: "/",
    name: "HomePage",
    components: {
      header: AppHeader,
      default: HomePage,
      footer: AppFooter,
    }
  },
  {
    path: i18n.t('vueRoutes.wines'),
    alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRoutes.wines', locale.key) ),
    name: 'Wines',
    components: {
      header: AppHeader,
      default: WinesPage,
      footer: AppFooter
    }
  },
  {
    path: `${i18n.t('vueRoutes.wines')}/:wineSlug`,
    alias: CONSTANTS.supportedLanguages.map(locale => `${i18n.t('vueRoutes.wines', locale.key)}/:wineSlug` ),
    name: 'WineDetail',
    components: {
      header: AppHeader,
      default: WineDetail,
      footer: AppFooter
    }
  },
  {
    path: i18n.t('vueRoutes.contact'),
    alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRoutes.contact', locale.key) ),
    name: 'Contact',
    components: {
      header: AppHeader,
      default: Contact,
      footer: AppFooter
    }
  },
  {
    path: i18n.t('vueRoutes.gallery'),
    alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRoutes.gallery', locale.key) ),
    name: 'Gallery',
    components: {
      header: AppHeader,
      default: Gallery,
      footer: AppFooter
    }
  },
  {
    path: `${i18n.t('vueRoutes.teamDetail')}/:memberSlug`,
    alias: CONSTANTS.supportedLanguages.map(locale => `${i18n.t('vueRoutes.teamDetail', locale.key)}/:memberSlug` ),
    name: 'TeamDetail',
    components: {
      header: AppHeader,
      default: TeamDetail,
      footer: AppFooter
    },
  },
  {
    path: "/:slug",
    name: 'StaticInfos',
    components: {
      header: AppHeader,
      default: StaticInfos,
      footer: AppFooter
    },
  },
  {
    path: '*',
    name: '404',
    components: {
      header: AppHeader,
      default: Page404,
      footer: AppFooter
    }
  }, 
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
